import './StrategyListItem.scss';
import { MicroCard } from '../MicroCard/MicroCard';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ReactComponent as HintIcon } from '../../assets/info.svg';

interface StrategyListItemProps {
    title: string;
    description: string;
    percent: number;
    value?: string;
    children?: string | JSX.Element | JSX.Element[];
    color: string;
    amount: number|string;
    apr: number;
    icon: string;
    primaryIcon: string;
    secondaryIcon: string;
    address: string;
}

export const StrategyListItem: React.FC<
    StrategyListItemProps & React.HTMLProps<HTMLDivElement>
> = ({
    address,
    className,
    title,
    description,
    percent,
    color,
    amount,
    apr,
    icon,
    primaryIcon,
    secondaryIcon,
    type
}): JSX.Element => {
    return (
        <div className={`strategy-list-item ${className ?? ''}`}>
            <div className="">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="wrapper me-1">
                        <div className="coin">
                            <img src={primaryIcon} alt="" />
                        </div>
                        <div className="coin">
                            <img src={secondaryIcon} alt="" />
                        </div>
                    </div>
                    <div className="flex-grow-1">
                        <a className="name" href={`https://etherscan.io/address/${address}`} target='_blank' rel="noreferrer">
                            {title}
                            {Number(apr) === 0 && title !== 'Vault' && (
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip>This strategy does not currently stream rewards. The rewards will be streamed for a rolling 7-day period as soon as it has been harvested.</Tooltip>}
                                >
                                    <HintIcon className="ms-1" />
                                </OverlayTrigger>
                            )}
                        </a>
                        <div className="description">{description}</div>
                    </div>
                    <div className="percent-val">{percent.toFixed(2)}%</div>
                </div>
            </div>
            <div className="d-flex gap-2">
                <MicroCard
                    title="Amount"
                    value={amount}
                    className="align-items-start flex-even"
                />
                <MicroCard title="APR" value={`${apr}%`} className="align-items-start flex-even" />
            </div>
            <div className="t2hird-row mt-3">
                <div
                    className="progress"
                    style={{
                        width: '100%',
                        height: '4px',
                    }}
                >
                    <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                            width: `${percent}%`,
                            backgroundColor: color,
                        }}
                    ></div>
                </div>
            </div>
        </div>
    );
};
