import { useState, useEffect } from 'react';

export const ETHERSCAN_API_KEY = 'UPPVCS8VTCCNT3T83BZ8H6YRE9WVDY6W2P';

export const useGasPrice = () => {
    const [gasPrice, setGasPrice] = useState('');

    useEffect(() => {
        fetch(`https://brain.bitfo.com/api/gas/estimated_base_fees`)
            .then((response) => response.json())
            .then((data) => {
                data = data.sort((a: any, b: any) => new Date(a.time).getTime() - new Date(b.time).getTime());
                setGasPrice(data[data.length - 1].value.toFixed(0));
            }).catch((error) => {
                setGasPrice('n/a');
            });
    }, []);

    return gasPrice;
};
