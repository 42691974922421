import { createPublicClient, createWalletClient, custom, http } from 'viem';
import { mainnet } from 'viem/chains';

// export const infuraRpcTransportUrl = `https://lb.drpc.org/ogrpc?network=ethereum&dkey=AvTrpkqK10shjlNk2y7YOgQAeihbH00R766FtigucSjy`;
export const infuraRpcTransportUrl = 'https://eth-mainnet.g.alchemy.com/v2/b7b-NDa8uY1HISXI79q2I6FgipeNi8jk';
export const infuraSepoliaRpcTransportUrl = `https://eth-sepolia-public.unifra.io`;

export const publicClient = createPublicClient({
    chain: mainnet,
    transport: http(infuraRpcTransportUrl),
});

export const walletClient = createWalletClient({
    chain: mainnet,
    transport: window.ethereum ? custom(window.ethereum) : http(infuraRpcTransportUrl),
});
