import BigNumber from 'bignumber.js';
import { useEffect, useState } from 'react';
import { BIG_TEN, BIG_ZERO } from '../utils/formatbalance';
import { Address, useNetwork } from 'wagmi';
import { getChainClient } from '../utils/zunami';
import apsAbi from '../actions/abi/aps.json';

const useLpPrice = (contractAddress: Address) => {
    const { chain } = useNetwork();
    const chainId = chain ? chain.id : undefined;
    const [balance, setBalance] = useState(new BigNumber(BIG_ZERO));

    useEffect(() => {
        const fetchBalance = async () => {
            let result: BigInt = BigInt(0);

            // @ts-ignore
            result = await getChainClient(chainId).readContract({
                address: contractAddress,
                abi: apsAbi,
                functionName: 'tokenPrice',
            });

            setBalance(new BigNumber(result.toString()).dividedBy(BIG_TEN.pow(18)));
        };

        fetchBalance();

        let refreshInterval = setInterval(fetchBalance, 60000);
        return () => clearInterval(refreshInterval);
    }, [chainId, contractAddress]);

    return balance;
};

export default useLpPrice;
