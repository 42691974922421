import { format } from 'date-fns';
// import { useWallet } from 'use-wallet';
import './LockHistory.scss';
import { getScanAddressByChainId } from '../../utils/zunami';
import { UserLock } from '../../containers/Uzd';
import { getFullDisplayBalance } from '../../utils/formatbalance';
import BigNumber from 'bignumber.js';
import moment from 'moment';
// import momentDurationFormatSetup from "moment-duration-format/lib/moment-duration-format";

// momentDurationFormatSetup(moment);

interface TransactionHistoryProps {
    items: Array<UserLock>;
    onPageEnd?: Function;
    emptyText: string;
    columns: Array<string>;
    onUnlockClick?: Function;
    onUnlockWithPenaltyClick?: Function;
}

export const LockHistory: React.FC<TransactionHistoryProps & React.HTMLProps<HTMLDivElement>> = ({
    className,
    items,
    emptyText,
    onUnlockClick,
    onUnlockWithPenaltyClick,
    columns,
}) => {

    // items.map((item, index) => {
    //     console.log(item);
    //     console.log(index, Number(item.timeInterval));
    // });

    const currentTs = new Date().getTime() / 1000;

    return (
        <div className={`LockHistory ${className}`}>
            <div className="d-flex header">
                {columns.map((column: string, key: number) => (
                    <div key={key}>{column}</div>
                ))}
            </div>
            <div className="LockHistory__List">
                {items &&
                    items.map((item, index) => (
                        <div className={`LockHistory__List-Item d-flex border-chameleon ${!Number(item.amount) || !Number(item.unlockTs) ? 'd-none' : ''}`} key={index}>
                            <div className="amount font-bold text-chameleon">
                                {getFullDisplayBalance(new BigNumber(item.amount.toString()))}
                            </div>
                            <div className={`lock-period font-bold text-chameleon`}>
                                { moment.duration(currentTs - Number(item.unlockTs), 'seconds').humanize() }
                            </div>
                            <div className="actions d-flex gap-2 align-items-center">
                                <button
                                    className={`lock-btn zun-button ${!item.unlocked ? 'disabled' : ''}`}
                                    onClick={() => {
                                        if (onUnlockClick) {
                                            onUnlockClick(index);
                                        }
                                    }}
                                >Unlock</button>
                                {
                                    !item.unlocked && (
                                        <div
                                            className="unlock-with-penalty text-chameleon"
                                            onClick={() => {
                                                if (onUnlockWithPenaltyClick) {
                                                    onUnlockWithPenaltyClick(index);
                                                }
                                            }}
                                        >Unlock with 15% Penalty Fee</div>
                                    )
                                }
                            </div>
                        </div>
                    ))}
                {!items.length && (
                    <div className="text-center empty">
                        <div className="">{emptyText}</div>
                    </div>
                )}
            </div>
        </div>
    );
};
